const pages = [
    { 
        path: "/services", 
        title: "Services", 
        children: [
            { path: "/services", title: "Custom Manure Pumping & Application" },
            { path: "/services/custom-manure-hauling", title: "Custom Manure Hauling" },
            { path: "/services/custom-haylage-silage-hauling", title: "Custom Haylage & Silage Hauling" },
        ]  
    },
    { 
        path: "/contact", 
        title: "Contact",
        children: [
            { path: "/contact", title: "General Inquiry" },
            { path: "/contact/showing", title: "Request a Showing" },
            { path: "/contact/buying", title: "Contact Us Buying" },
            { path: "/contact/selling", title: "Contact Us Selling" },
            { path: "/contact/testimonial", title: "Submit Your Testimonial" },
        ] 
    },
    { path: "/about-us", title: "About Us" },
    { path: "/testimonials", title: "Testimonials" },
    { path: "/resources", title: "Resources" },
]

export default pages